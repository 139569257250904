.latest_block {
  background-color: #FFFFFF;
  position: relative;

  .slick-track {
    > div {
      > div {
        margin: 0 10px;
      }
    }
  }

  .news_nth {
    height: 590px;
    background: #FFFFFF;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.288);
    }

    .news_image {
      width: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
    }

    .news_info {
      padding: 20px;

      .news_title {
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
      }

      .news_desc {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
      }
    }

  }

  .slick-slide {
    padding-bottom: 120px;

  }

  .slick-dots {
    bottom: 0;

    .slick-active {
      button:before {
        color: #A7CEFF;
      }
    }
  }

  .mobile_latest {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    .news_nth {
      display: flex;
      flex-direction: row;
      gap: 10px;
      height: unset;
      box-shadow: unset;

      > div {
        width: 50%;
      }

      .news_image {
        justify-content: start;
        align-items: start;
        margin-right: 10px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .news_info {
        padding: 0;

        .news_title {
          font-size: 20px;
          line-height: 24px;
          display: block;
          overflow: unset;
        }

        .news_desc {
          font-size: 16px;
          line-height: 20px;
          -webkit-line-clamp: 4;
        }
      }
    }

    .show_more {
      position: absolute;
      right: 3.84%;
      bottom: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #A7CEFF;
      cursor: pointer;
      >span {
        margin-right: 5px;
      }
    }

    .less {
      img {
        transform: rotate(180deg);
      }
    }

    @media screen and (max-width: 470px) {
      grid-template-columns: 1fr;
      gap: 10px;

      .news_nth {
        justify-content: start;
        gap: 10px;

        > div {
          width: 50%;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #2B4C75;
          padding-bottom: 10px;
          border-radius: 0;
        }
        .news_info {

          .news_title {
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 5px;
          }

          .news_desc {
            font-size: 10px;
            line-height: 12px;

          }
        }
      }
      .show_more {
        right: 10px;
      }
    }
  }
}
