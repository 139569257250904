.custom_select {
  position: relative;
  width: 56px;
}

.custom_select_inner {
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    position: relative;
    &:first-of-type {
      margin-right: 5px;
    }
  }
  i {
    margin-left: 5px;
    padding: 5px;
    font-size: 5px;
    opacity: .6;
    cursor: pointer;
  }

  &:focus-within,
  &.active {
    border: 2px solid var(--green);
  }
}

.custom_select_info {
  position: absolute;
  top: 25px;
  left: 0;
  display: none;
  width: 100%;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15),
  0 1px 2px rgba(0, 0, 0, 0.3);
  z-index: 9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;

  background: #FFFFFF;
  animation: fadeIn 0.2s ease-in-out;

  img {
    width: 24px;
    cursor: pointer;
    transition-duration: 0.2s;
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
    &:hover {
      transform: scale(1.2);
    }
  }

  &.open {
    display: flex;
  }

}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
