.partners_block {
  padding-top: 100px !important;
  background-color: #FFFFFF;

  .partner_nth {
    display: flex !important;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
    }

    > img {
      object-fit: contain;
      @media screen and (max-width: 1560px) {
        height: 90px;
      }
      @media screen and (max-width: 1200px) {
        height: 70px;
      }
      @media screen and (max-width: 991px) {
        height: 60px;
      }
      @media screen and (max-width: 768px) {
        height: 45px;
      }
      @media screen and (max-width: 576px) {
        height: 30px;
      }
      @media screen and (max-width: 375px) {
        height: 21.6px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    padding-top: 30px !important;
  }
  @media screen and (max-width: 470px) {
    padding-top: 20px !important;
  }
}
