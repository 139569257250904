.home_page {
  color: #2B4C75;
  .home_block {
    padding: 80px 16.15%;
    @media screen and (max-width: 1560px) {
      padding: 60px 8%;
    }
    @media screen and (max-width: 991px) {
      padding: 30px 8%;
    }
    @media screen and (max-width: 768px) {
      padding: 20px 3.84%;
    }
    @media screen and (max-width: 470px) {
      padding: 20px 10px;
    }
  }
  .home_desc {
    font-size: 18px;
    line-height: 22px;
    @media screen and (max-width: 1200px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media screen and (max-width: 991px) {
      font-size: 12px;
      line-height: 17px;
    }
  }
}
