footer {
  padding: 40px 16.14% 30px;
  background-color: #F6F6F6;
  color: #2B4C75;

  .footer_block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title_footer {
      font-weight: 500;
      font-size: 32px;
      line-height: 39px;
      margin-bottom: 30px;
      cursor: pointer;
    }
    .desc_footer {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
    .footer_address {
      width: 32.3%;
    }
    .footer_contact {
      display: flex;
      flex-direction: column;
      align-items: end;
      width: 50%;

      .contact_info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .info_nth {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;

          a {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
          }
        }

      }
      .social_icons {
        margin-top: 20px;
        >a {
          &:first-of-type {
            margin-right: 20px;
          }
        }
      }
    }
    @media screen and (max-width: 1560px) {
      gap: 90px;
      .footer_address {
        width: 50%;
      }
    }
    @media screen and (max-width: 768px) {
     flex-direction: column;
      gap: unset;
      .footer_address {
        width: 100%;
        padding-bottom: 5px;
        border-bottom: 1px solid #A7CEFF;
        .desc_footer {
          width: 85%;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
          overflow: hidden;
        }
        margin-bottom: 10px;
      }
      .title_footer {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 10px;
      }
      .footer_contact {
        align-items: start;
        width: 100%;
        .contact_info {
          gap: 5px;
          .info_nth {
            &:nth-of-type(2) {
              display: none;
            }
            a {
              font-size: 16px;
              line-height: 19px;
            }
            img {
              width: 12px;
            }
          }

        }
        .social_icons {
          margin-top: 0;
          img {
            width: 30px;
          }
          a {
            &:first-of-type {
              margin-right: 12px;
            }
          }
        }
        >div {
          &:first-of-type {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
          }
        }
      }

    }
    @media screen and (max-width: 470px) {
      .footer_address {
        .desc_footer {
          font-size: 12px;
          line-height: 15px;
        }
      }
      .title_footer {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 5px;
      }
      .footer_contact {
        .contact_info {
          .info_nth {
            gap: unset;
            a {
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1560px) {
    padding: 40px 8% 30px;
  }
  @media screen and (max-width: 768px) {
    padding: 20px 3.84% 17px;
  }
  @media screen and (max-width: 470px) {
    padding: 20px 10px 17px;
  }
}