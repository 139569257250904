.advantages_block {
  background-color: #F6F6F6;

  .advantages_main {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    gap: 28px;
    position: relative;

    .img_part {
      position: absolute;
      bottom: 0;
      left: calc(50% - 202px);

      .circle {
        width: 404px;
        max-width: 404px;
        height: 404px;
        background: #A7CEFF;
        border-radius: 50%;
      }

      img {
        position: absolute;
        bottom: 0;
        left: 98px;
      }
    }

    .left_part {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 32.3%;
      min-width: 300px;
      z-index: 9;
    }

    .advantage_nth {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 120px;
      background: #FFFFFF;
      border: 3px solid #F0F0F0;
      box-shadow: 3px 3px 25px rgba(80, 80, 80, 0.15);
      border-radius: 5px;
      position: relative;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.288);
      }

      .advantage_desc {
        font-weight: 500;
        text-align: end;
        padding: 0 30px 0 20px;
        width: 330px;
      }

      .advantage_number {
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 21%;
        min-width: 80px;
        height: 100%;
        background: #A7CEFF;
        border-radius: 5px 0 0 5px;
      }
    }

    .right_part {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 32.3%;
      min-width: 300px;
      z-index: 9;

      .advantage_nth {
        flex-direction: row-reverse;

        .advantage_number {
          border-radius: 0 5px 5px 0;
        }
      }
    }

    @media screen and (max-width: 768px) {
      gap: 5px;
      .right_part, .left_part {
        gap: 5px;
        min-width: 200px;
        width: 50%;
      }
      .advantage_nth {
        height: 80px;

        .advantage_desc {
          padding: 0 10px;
        }

        .advantage_number {
          font-size: 16px;
          line-height: 19px;
          width: 25%;
          min-width: 50px;
        }
      }
      .img_part {
        display: none;
      }
    }
    @media screen and (max-width: 470px) {
      flex-direction: column;
      .right_part, .left_part {
        min-width: 240px;
        width: 100%;
      }
      .advantage_nth {
        height: 55px;

        .advantage_desc {
          padding: 0 12px;
          font-size: 12px;
          line-height: 15px;
        }

        .advantage_number {
          font-size: 12px;
          line-height: 15px;
          width: 13.3%;
          min-width: 32px;
        }
      }
      .right_part {
        .advantage_nth {
          flex-direction: row;

          .advantage_number {
            border-radius: 5px 0 0 5px;
          }
        }
      }
    }
  }
}
