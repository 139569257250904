.main_logo {
  @media screen and (max-width: 1200px) {
    > svg {
      width: 60px;
      height: 40px;
    }
  }
  @media screen and (max-width: 385px) {
    > svg {
      width: 30px;
      height: 25px;
    }
  }
}