.service_block {
  background-color: #F6F6F6;

  .service_items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
    grid-gap: 20px;

    .service_nth {
      text-align: center;
      max-width: 420px;
      height: 300px;
      padding: 20px 30px;
      background: #FFFFFF;
      border: 3px solid #F0F0F0;
      box-shadow: 3px 3px 25px rgba(80, 80, 80, 0.15);
      border-radius: 10px;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.288);
      }

      .service_icon {
        height: 104px;
      }

      .service_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        padding: 25px 0 20px;
      }

      .service_desc {
        font-weight: 500;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
      .service_nth {
        padding: 10px 20px;
        height: 130px;

        .service_title {
          font-size: 18px;
          line-height: 22px;
          padding-top: 5px;
          padding-bottom: 0;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
          overflow: hidden;
        }

        .service_desc {
          display: none;
        }

        .service_icon {
          height: 60px;

          img {
            width: 60px;
          }
        }
      }
    }
    @media screen and (max-width: 385px) {
      grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));
      gap: 10px;
      .service_nth {
        height: 85px;

        .service_title {
          font-size: 12px;
          line-height: 15px;

        }

        .service_icon {
          height: 30px;

          img {
            width: 30px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 470px) {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

