header {
  padding: 10px 16.15% 0;

  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #2B4C75;;

    .menu_items {
      display: flex;
      align-items: center;
      gap: 20px;

      .nth_item {
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;

        &.active {
          font-weight: 700;
          border-bottom: 1px solid;
        }

        &:hover {
          border-bottom: 1px solid;
        }
      }

      @media screen and (max-width: 978px) {
        gap: 15px;
        .nth_item {
          font-size: 14px;
          line-height: 20px;
        }
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .select_languages {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }
  }

  @media screen and (max-width: 1560px) {
    padding: 10px 8% 0;
  }
  @media screen and (max-width: 768px) {
    padding: 10px 3.84% 0;
  }
  @media screen and (max-width: 470px) {
    padding: 10px 10px 0;
  }
}
