.banner_block {
  padding: 80px 0 43px 0;
  .slick-slider {
    overflow: hidden;
    padding-bottom: 78px;

    .slick-track {
      width: 100%;
    }
  }
  .slick-dots {
    bottom: 0;

    .slick-active {
      button:before {
        color: #A7CEFF;
      }
    }
  }
  .banner_item {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 120px;
    color: #2B4C75;
    padding: 0 16.15%;
    background-color: #FFFFFF;
    .banner_img {
      width: 48.5%;
    }
    .banner_info {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 43%;
      .item_title {
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
        text-transform: capitalize;
        @media screen and (max-width: 1200px) {
          font-size: 32px;
          line-height: 38px;
        }
        @media screen and (max-width: 1200px) {
          font-size: 24px;
          line-height: 26px;
        }
      }
      .item_desc {
        font-weight: 400;
      }
      @media screen and (max-width: 991px) {
        gap: 10px;
      }
    }
    form {
      display: flex;
      flex-direction: row;
      gap: 20px;
      height: 40px;
      input {
        appearance: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #989898;
        padding: 10px 20px;
        border: 1px solid #2B4C75;
        border-radius: 5px;
        width: 280px;
        @media screen and (max-width: 1560px) {
          width: 210px;
        }
        &:focus {
          outline: none;
        }
      }
      button {
        padding: 11.5px 17px;
        background-color: #FFB800;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #001D41;
        border-radius: 5px;
        border: 1px solid #FFB800;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          border: 1px solid #FFB800;
          background-color: #FFFFFF;
        }
      }
      @media screen and (max-width: 991px) {
        justify-content: space-between;
        gap: 10px;
        input, button {
          width: 50%;
        }
        input {
          padding: 10px;
          font-size: 14px;
        }
      }
      @media screen and (max-width: 991px) {
        justify-content: space-between;
        gap: 10px;
        input, button {
          width: 50%;
        }
        input {
          padding: 10px;
          font-size: 14px;
        }
      }
    }
    .mobile_form {
      width: 100%;
      display: none;

    }
    @media screen and (max-width: 1560px) {
      gap: 30px;
      padding: 0 8%;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 0 3.84%;
      .banner_info, .banner_img {
        width: 100%;
      }
      .desktop_form {
        display: none;
      }
      .mobile_form {
        display: flex;
        gap: 10px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 30px;
    .slick-slider {
      padding-bottom: 0;
    }
  }
  @media screen and (max-width: 470px) {
    padding-top: 20px;
    padding-bottom: 10px;
    .banner_item {
      gap: 10px;
      .banner_info {
        gap: 5px;
        .item_title {
          font-size: 16px;
          line-height: 19px;
        }
        .item_desc {
          font-size: 8px;
          line-height: 10px;
        }
      }
      .mobile_form {
        height: unset;
        gap: 5px;
        margin-top: 10px;
        input {
          font-size: 12px;
          line-height: 15px;
          color: #2B4C75;
          height: 35px;
        }
        button {
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.02em;
          padding: 9px 14px;
          height: 35px;
        }
      }
    }
  }
}
