@font-face {
  font-family: "inter";
  src: url("./assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: inter;
}
.App {
  a {
    text-decoration: none;
    color: unset;
  }
  .block_title {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #028A7E;
    margin-bottom: 60px;
    cursor: pointer;
    @media screen and (max-width: 991px) {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
    @media screen and (max-width: 576px) {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
    }
    @media screen and (max-width: 470px) {
      margin-bottom: 10px;
    }
  }
}

.main_page {
  padding: 60px 16.15%;
  text-align: center;
  height: 100vh;
  color: #2B4C75;
}
